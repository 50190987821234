import React, { Component } from 'react'
import Image from 'next/image'
import beeperLogo from '../../assets/beeper.svg'
import ycombinatorLogo from '../../assets/ycombinator.png?w=234&h=63  '
import wrenLogo from '../../assets/wren.png'
import mindforgeLogo from '../../assets/mindforge.png'
import revoLogo from '../../assets/revo.svg'

export default class TrustedBy extends Component {
  render() {
    const beeperStyle = {
      backgroundColor: 'black',
      borderRadius: '5px',
      padding: '8px 8px 5px',
      display: 'flex',
      justifyContent: 'center',
    }

    return (
      <div className="Home-TrustedBy">
        <div className="logos">
          <div>
            <a target="_blank" rel="noopener noreferrer" href="https://ycombinator.com">
              <Image width={234} height={63} alt="YCombinator (S19)" src={ycombinatorLogo} />
            </a>
          </div>
          <div className="beeper" style={{ ...beeperStyle, width: '170px' }}>
            <a target="_blank" rel="noopener noreferrer" href="https://beeperhq.com">
              <Image width={153} height={35} alt="Beeper" src={beeperLogo} />
            </a>
          </div>
          <div className="beeper" style={{ ...beeperStyle, width: '180px' }}>
            <a target="_blank" rel="noopener noreferrer" href="https://mindforge.live/">
              <Image width={310} height={78} alt="MindForge" src={mindforgeLogo} />
            </a>
          </div>
          <div>
            <a target="_blank" rel="noopener noreferrer" href="https://www.wren.co/">
              <Image width={167} height={51} alt="Project Wren" src={wrenLogo} />
            </a>
          </div>
          <div>
            <a target="_blank" rel="noopener noreferrer" href="https://revo.network/">
              <Image width={167} height={51} alt="Revo Network" src={revoLogo} />
            </a>
          </div>
          <div>and thousands of home users!</div>
        </div>
      </div>
    )
  }
}
